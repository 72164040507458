import type { IconBase } from "$lib/domain/icons-base.interface";
export const technologyIcons: IconBase = {

		dotNet: '<svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60" fill="none">\n' +
				'  <path fill-rule="evenodd" clip-rule="evenodd" d="M37.2107 35.7473C37.4014 35.2566 37.6177 34.7736 37.779 34.2733C38.6902 31.4469 39.5927 28.6181 40.4891 25.7869C40.8336 24.6993 41.2112 23.6283 41.889 22.6936C42.9611 21.2165 44.4546 20.4427 46.222 20.1723C46.9154 20.0663 47.6231 20.0173 48.3248 20.0106C50.4224 19.9907 52.5209 20.0046 54.6189 20.0066C54.7304 20.0066 54.8419 20.0181 55 20.0273C54.9486 20.1484 54.9207 20.2364 54.8761 20.3156C54.0976 21.7072 53.3341 23.107 52.5324 24.4846C51.1365 26.8829 49.7382 29.2804 48.2985 31.6524C47.375 33.1734 46.3734 34.6473 45.2371 36.0229C44.3988 37.0376 43.5163 38.0078 42.3912 38.7191C41.6246 39.2038 40.8057 39.4873 39.8762 39.4825C36.5794 39.4646 33.2827 39.4778 29.9859 39.473C29.0058 39.4714 28.1667 39.0982 27.4546 38.4363C26.4339 37.4881 25.8245 36.281 25.3259 35.0081C24.4163 32.6843 23.9069 30.2486 23.3223 27.8339C23.0483 26.7037 22.7368 25.5822 22.4401 24.4575C22.421 24.3846 22.3836 24.3165 22.3406 24.2118L22.1108 24.4276C20.8252 25.7049 19.693 27.1123 18.6798 28.6085C18.4038 29.0164 18.311 29.554 18.1569 30.0387C17.5898 31.8189 17.0728 33.6162 16.4587 35.3797C15.8705 37.0691 14.8159 38.4128 13.1675 39.2069C12.2037 39.6709 11.1746 39.9218 10.1053 39.9457C8.52027 39.9808 6.93441 39.9847 5.34895 39.9991C4.96981 40.0027 4.95229 39.9716 5.05106 39.6048C5.73088 37.0807 6.59231 34.621 7.67158 32.2402C8.78431 29.7842 10.095 27.442 11.7461 25.303C12.8704 23.8466 14.1122 22.5029 15.6662 21.4869C16.4543 20.972 17.2923 20.565 18.2457 20.4578C18.4026 20.4399 18.5615 20.4287 18.7192 20.4287C22.0956 20.4272 25.4721 20.4614 28.8481 20.4132C30.659 20.3873 31.97 21.152 32.9517 22.6096C33.5622 23.516 33.9991 24.5057 34.3667 25.528C35.1079 27.5902 35.5687 29.7284 36.0892 31.8515C36.3871 33.0666 36.7188 34.2733 37.0366 35.4836C37.0605 35.5744 37.0992 35.6613 37.1314 35.7497L37.2107 35.7473Z" fill="#F7F7F7"/>\n' +
				'</svg>',

		java: '<svg xmlns="http://www.w3.org/2000/svg" width="45" height="60" viewBox="0 0 45 60" fill="none">\n' +
				'  <path d="M22.456 11.1172C19.6836 13.0671 16.5414 15.2773 14.8579 18.9338C11.9336 25.3127 20.8417 32.2711 21.2224 32.5642C21.3109 32.6325 21.4168 32.6663 21.5224 32.6663C21.6345 32.6663 21.7468 32.628 21.8381 32.5516C22.015 32.4037 22.0651 32.1525 21.9589 31.9479C21.9267 31.8857 18.7291 25.6748 18.848 21.2189C18.8901 19.6671 21.0619 17.9067 23.3611 16.0427C25.4671 14.3356 27.8541 12.4006 29.2508 10.1372C32.3169 5.15479 28.9091 0.255638 28.8743 0.206851C28.7389 0.016818 28.4891 -0.0522313 28.2755 0.0416049C28.062 0.135638 27.9438 0.366589 27.9926 0.594589C27.9995 0.626852 28.6622 3.86666 26.8181 7.20444C26.0582 8.58365 24.389 9.75788 22.456 11.1172Z" fill="#F7F7F7"/>\n' +
				'  <path d="M34.1348 13.3139C34.3304 13.1666 34.3878 12.8979 34.2694 12.6834C34.1509 12.469 33.893 12.3744 33.6641 12.4613C33.1931 12.6403 22.1309 16.9043 22.1309 22.0566C22.1309 25.608 23.648 27.4844 24.7557 28.8545C25.1903 29.3918 25.5654 29.8559 25.689 30.2521C26.037 31.3938 25.2125 33.4574 24.8659 34.1562C24.7659 34.3572 24.8149 34.6006 24.9849 34.7473C25.0768 34.8266 25.1915 34.8667 25.3063 34.8667C25.4041 34.8667 25.5023 34.8376 25.5871 34.7786C25.7773 34.6462 30.2356 31.4942 29.4359 27.6964C29.1381 26.2531 28.4287 25.1983 27.8027 24.2676C26.8331 22.8258 26.1328 21.7842 27.1953 19.8604C28.4405 17.617 34.078 13.3566 34.1348 13.3139Z" fill="#F7F7F7"/>\n' +
				'  <path d="M5.33334 34.9494C5.1437 35.517 5.23321 36.0806 5.59203 36.5789C6.79125 38.2435 11.0389 39.1604 17.5531 39.1604C17.5535 39.1604 17.5537 39.1604 17.5539 39.1604C18.4366 39.1604 19.3684 39.1431 20.3229 39.1089C30.738 38.7359 34.601 35.4914 34.7603 35.3535C34.934 35.2032 34.9805 34.9528 34.8725 34.7501C34.7645 34.5477 34.5316 34.446 34.3091 34.5072C30.6412 35.5087 23.7917 35.8642 19.0409 35.8642C13.7249 35.8642 11.0176 35.4814 10.3549 35.1987C10.695 34.7317 12.7926 33.8978 15.3919 33.3865C15.6404 33.3377 15.8111 33.1083 15.7865 32.8563C15.7619 32.6043 15.5503 32.4121 15.2971 32.4121C13.7733 32.4123 6.13203 32.5347 5.33334 34.9494Z" fill="#F7F7F7"/>\n' +
				'  <path d="M40.1907 31.6077C38.0425 31.6077 36.0055 32.6841 35.9197 32.73C35.721 32.8364 35.6203 33.0642 35.6748 33.2829C35.7295 33.5015 35.926 33.6551 36.1514 33.6553C36.1971 33.6555 40.7337 33.6925 41.1446 36.2845C41.5086 38.5207 36.8522 42.1439 35.0272 43.3514C34.8301 43.4818 34.7535 43.734 34.845 43.9518C34.9227 44.1373 35.1035 44.2533 35.2983 44.2533C35.3323 44.2533 35.3667 44.2498 35.4009 44.2423C35.8341 44.1499 45.9954 41.9108 44.9211 35.9944C44.2656 32.3686 41.9868 31.6077 40.1907 31.6077Z" fill="#F7F7F7"/>\n' +
				'  <path d="M33.2165 42.6272C33.2519 42.4375 33.1731 42.2447 33.0153 42.1342L30.5789 40.4276C30.4601 40.3444 30.31 40.3182 30.1705 40.3552C30.1451 40.3619 27.5972 41.0316 23.898 41.4423C22.4299 41.607 20.7855 41.6939 19.1423 41.6939C15.4441 41.6939 13.026 41.2594 12.6739 40.9407C12.6273 40.8512 12.642 40.8104 12.6503 40.7878C12.7142 40.6098 13.0571 40.3983 13.2782 40.3147C13.5223 40.2244 13.6539 39.9592 13.578 39.7102C13.5023 39.4611 13.2456 39.3144 12.9922 39.3754C10.5481 39.9651 9.35302 40.79 9.43997 41.8267C9.5944 43.6647 13.852 44.6081 17.4515 44.8578C17.969 44.8932 18.5289 44.9113 19.1149 44.9113C19.1153 44.9113 19.1155 44.9113 19.1159 44.9113C25.1012 44.9113 32.7741 43.0334 32.8508 43.0143C33.0381 42.9681 33.1809 42.8168 33.2165 42.6272Z" fill="#F7F7F7"/>\n' +
				'  <path d="M14.8553 46.5725C15.0398 46.4523 15.1216 46.2243 15.0561 46.0142C14.9908 45.8041 14.7943 45.6662 14.5735 45.669C14.246 45.6776 11.3686 45.8075 11.1671 47.6395C11.1061 48.187 11.2629 48.6871 11.6333 49.126C12.6665 50.3504 15.4553 51.078 20.1577 51.3509C20.714 51.3841 21.279 51.4009 21.8375 51.4009C27.8159 51.4009 31.8422 49.5291 32.0108 49.4494C32.1742 49.3721 32.282 49.2116 32.2917 49.031C32.3013 48.8506 32.2112 48.6792 32.057 48.585L28.9779 46.7053C28.8711 46.6402 28.7432 46.6186 28.6212 46.6435C28.6018 46.6477 26.6467 47.0531 23.6963 47.4538C23.138 47.5296 22.4389 47.5681 21.6185 47.5681C18.6713 47.5681 15.3931 47.0866 14.7644 46.7714C14.7553 46.7122 14.7669 46.6374 14.8553 46.5725Z" fill="#F7F7F7"/>\n' +
				'  <path d="M19.047 57.0521C32.7407 57.0405 40.0905 54.6053 41.5063 53.0729C42.0075 52.5305 42.0616 52.0173 42.0189 51.682C41.9137 50.857 41.1624 50.352 41.077 50.2971C40.8715 50.1649 40.5956 50.1995 40.4353 50.3845C40.2754 50.5696 40.2716 50.8397 40.4322 51.0244C40.5185 51.1356 40.5685 51.3214 40.3159 51.5742C39.7499 52.1028 34.0411 53.7075 24.5359 54.1895C23.2338 54.2573 21.8681 54.2916 20.4769 54.2917C11.9664 54.2917 5.73795 53.1262 4.92037 52.4467C5.23572 51.994 7.43861 51.2705 9.78156 50.8615C10.0456 50.8155 10.224 50.5666 10.1831 50.3018C10.1422 50.0371 9.89802 49.8547 9.63127 49.8895C9.56517 49.8984 9.3411 49.9121 9.08123 49.9279C5.21546 50.1639 0.748103 50.6902 0.514987 52.6818C0.444168 53.2889 0.624561 53.8397 1.05125 54.3193C2.09525 55.4923 5.09624 57.052 19.0464 57.052C19.0468 57.0521 19.0468 57.0521 19.047 57.0521Z" fill="#F7F7F7"/>\n' +
				'  <path d="M44.1959 53.5052C44.0024 53.4157 43.7738 53.461 43.6292 53.6168C43.6089 53.6384 41.5502 55.7913 35.36 57.0555C32.9901 57.5304 28.5416 57.7712 22.1381 57.7712C15.7226 57.7712 9.61817 57.5188 9.55719 57.5162C9.30223 57.5038 9.08407 57.6891 9.04945 57.9401C9.01463 58.1911 9.17633 58.4274 9.42263 58.486C9.48617 58.5012 15.8838 60 24.6312 60C28.8267 60 32.9092 59.6591 36.7658 58.9867C43.9565 57.7261 44.4599 54.1605 44.4778 54.0094C44.5028 53.7982 44.3891 53.5947 44.1959 53.5052Z" fill="#F7F7F7"/>\n' +
				'</svg>',

		angular: '<svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">\n' +
				'  <path fill-rule="evenodd" clip-rule="evenodd" d="M24 0L0 7.968L3.66058 37.512L24 48L44.3394 37.512L48 7.968L24 0ZM38.9804 36.6225H33.3864L30.3703 29.6145H24.003H17.6356L14.6195 36.6225H9.02555L24.003 5.3025L38.9804 36.6225ZM24.6285 25.2H20.2074L24.6285 15.6L29.0495 25.2H24.6285Z" fill="white"/>\n' +
				'</svg>',

		react: '<svg xmlns="http://www.w3.org/2000/svg" width="50" height="54" viewBox="0 0 50 54" fill="none">\n' +
				'  <path fill-rule="evenodd" clip-rule="evenodd" d="M0 27.3684C0 33.2595 11.2326 37.5212 25 37.5212C38.7674 37.5212 50 33.2595 50 27.3684C50 21.4772 38.7674 17.2155 25 17.2155C11.2326 17.2155 0 21.4772 0 27.3684ZM47.825 27.3688C47.825 31.4992 37.6452 35.3615 24.9989 35.3615C12.3527 35.3615 2.17285 31.4992 2.17285 27.3688C2.17285 23.2385 12.3527 19.3762 24.9989 19.3762C37.6452 19.3762 47.825 23.2385 47.825 27.3688Z" fill="white"/>\n' +
				'  <path fill-rule="evenodd" clip-rule="evenodd" d="M12.4959 5.09552C7.37485 8.05215 9.28652 19.9188 16.1702 31.8417C23.0539 43.7646 32.3749 51.3534 37.4959 48.3968C42.617 45.4401 40.7053 33.5735 33.8216 21.6506C26.9379 9.72773 17.617 2.13888 12.4959 5.09552ZM36.4104 46.5136C32.82 48.5866 24.3726 41.709 18.0495 30.757C11.7264 19.805 9.99389 9.05062 13.5843 6.97767C17.1748 4.90472 25.6222 11.7823 31.9453 22.7343C38.2684 33.6862 40.0009 44.4407 36.4104 46.5136Z" fill="white"/>\n' +
				'  <path fill-rule="evenodd" clip-rule="evenodd" d="M37.4959 5.09571C32.3749 2.13907 23.0539 9.72792 16.1702 21.6508C9.28652 33.5737 7.37485 45.4403 12.4959 48.397C17.617 51.3536 26.9379 43.7648 33.8216 31.8419C40.7053 19.919 42.617 8.05235 37.4959 5.09571ZM13.5854 46.514C9.99495 44.441 11.7274 33.6866 18.0506 22.7346C24.3737 11.7827 32.821 4.90509 36.4115 6.97804C40.0019 9.05099 38.2694 19.8054 31.9463 30.7574C25.6232 41.7093 17.1759 48.5869 13.5854 46.514Z" fill="white"/>\n' +
				'  <ellipse cx="25.0032" cy="27.3673" rx="5" ry="5.07642" fill="white"/>\n' +
				'</svg>',

		android: '<svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none">\n' +
				'  <path d="M45.5552 41.0159C43.132 41.0159 41.112 39.0447 41.112 36.6215V24.9032C41.112 22.48 43.132 20.5088 45.5552 20.5088C47.9784 20.5088 49.9984 22.48 49.9984 24.9032V36.6215C49.9984 39.0448 47.9785 41.0159 45.5552 41.0159Z" fill="white"/>\n' +
				'  <path d="M4.44338 41.0159C2.02023 41.0159 0.000183105 39.0447 0.000183105 36.6215V24.9032C0.000183105 22.48 2.02023 20.5088 4.44338 20.5088C6.86654 20.5088 8.88659 22.48 8.88659 24.9032V36.6215C8.88659 39.0448 6.86664 41.0159 4.44338 41.0159Z" fill="white"/>\n' +
				'  <path fill-rule="evenodd" clip-rule="evenodd" d="M38.1824 16.1144C38.1824 16.9348 37.538 17.5792 36.7176 17.5792H13.281C12.4606 17.5792 11.8162 16.9348 11.8162 16.1144C11.8162 11.0169 14.7457 6.65193 18.9936 4.45474L17.8217 2.11098C17.4702 1.40788 17.7632 0.529099 18.4956 0.148253C19.1987 -0.203297 20.0775 0.0897594 20.4583 0.822155L21.7474 3.39999C22.802 3.10713 23.8566 2.93136 24.9992 2.93136C26.1418 2.93136 27.1964 3.10713 28.251 3.39999L29.54 0.822155C29.9209 0.0897594 30.7998 -0.203297 31.5028 0.148253C32.2352 0.529099 32.5282 1.40797 32.1767 2.11098L31.0047 4.45474C35.2528 6.65193 38.1824 11.017 38.1824 16.1144ZM22.558 10.2552C22.558 11.0642 21.9022 11.72 21.0932 11.72C20.2842 11.72 19.6284 11.0642 19.6284 10.2552C19.6284 9.44623 20.2842 8.79042 21.0932 8.79042C21.9022 8.79042 22.558 9.44623 22.558 10.2552ZM28.9054 11.72C29.7144 11.72 30.3702 11.0642 30.3702 10.2552C30.3702 9.44623 29.7144 8.79042 28.9054 8.79042C28.0964 8.79042 27.4406 9.44623 27.4406 10.2552C27.4406 11.0642 28.0964 11.72 28.9054 11.72Z" fill="white"/>\n' +
				'  <path d="M36.7176 20.5088H13.281C12.4606 20.5088 11.8162 21.1532 11.8162 21.9736V39.5511C11.8162 40.3715 12.4606 41.0159 13.281 41.0159H14.7457V45.6056C14.7457 48.0372 16.7085 49.9999 19.1401 49.9999C21.5718 49.9999 23.5345 48.0372 23.5345 45.6056V41.0159H26.4641V45.6056C26.4641 48.0372 28.4268 49.9999 30.8585 49.9999C33.2901 49.9999 35.2528 48.0372 35.2528 45.6056V41.0159H36.7176C37.538 41.0159 38.1824 40.3715 38.1824 39.5511V21.9736C38.1824 21.1533 37.538 20.5088 36.7176 20.5088Z" fill="white"/>\n' +
				'</svg>',

		ios: '<svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none">\n' +
				'  <path d="M16.624 49.7996C15.2212 49.7448 13.1258 48.2155 12.2181 47.1864C9.89542 44.5528 8.00335 41.6346 6.66523 38.3828C5.14201 34.6814 4.2334 30.8428 4.27944 26.813C4.3125 23.9144 4.87835 21.1342 6.32944 18.604C8.12357 15.4757 10.7723 13.4183 14.3124 12.5866C15.721 12.2558 17.1569 12.123 18.5647 12.5395C19.9722 12.9559 21.3591 13.4474 22.7401 13.947C23.9367 14.3799 25.111 14.6932 26.3673 14.1488C27.1927 13.791 28.0512 13.5093 28.8965 13.1983C30.2811 12.6887 31.6805 12.2163 33.1711 12.1719C36.054 12.0858 38.7135 12.7929 41.0595 14.5187C42.027 15.2305 42.8402 16.0952 43.515 17.1084C43.4222 17.1783 43.3512 17.2362 43.2759 17.288C42.2655 17.9827 41.3707 18.8026 40.5967 19.7543C38.542 22.2801 37.8289 25.1633 38.3139 28.3605C38.6523 30.5914 39.6136 32.5172 41.1819 34.1405C42.1404 35.1325 43.7497 36.1702 44.9882 36.7716C44.7723 37.2834 44.3491 38.1092 44.1435 38.6257C43.2662 40.8304 42.0854 42.8655 40.6961 44.7849C39.7081 46.1498 38.7139 47.5032 37.364 48.5513C36.1289 49.5103 34.7618 49.9896 33.1865 49.8802C31.9865 49.7969 30.8758 49.422 29.8006 48.9243C27.4513 47.8366 25.04 47.6196 22.562 48.3952C21.9439 48.5887 21.3609 48.8922 20.7446 49.0925C19.7662 49.4104 17.6521 49.8398 16.624 49.7996Z" fill="white"/>\n' +
				'  <path d="M41.1817 34.1406C39.6134 32.5173 38.6521 30.5916 38.3138 28.3607C37.8288 25.1634 38.5419 22.2802 40.5966 19.7544C41.3706 18.8028 42.2654 17.9828 43.2758 17.2882C43.3511 17.2364 43.422 17.1785 43.5148 17.1085C42.84 16.0954 42.0268 15.2307 41.0593 14.5189C38.7133 12.7932 36.0537 12.0861 33.1709 12.172C31.6805 12.2166 30.2809 12.6889 28.8963 13.1984C28.051 13.5095 27.1924 13.7913 26.3671 14.149C25.754 14.4147 25.1605 14.4752 24.5742 14.4181V47.9824C26.3501 47.8055 28.0918 48.1335 29.8004 48.9245C30.8756 49.4223 31.9865 49.797 33.1863 49.8804C34.7618 49.9898 36.1288 49.5103 37.3638 48.5514C38.7137 47.5033 39.7079 46.1499 40.6959 44.785C42.0853 42.8656 43.266 40.8305 44.1433 38.6259C44.3488 38.1095 44.7721 37.2837 44.988 36.7718C43.7495 36.1704 42.1402 35.1327 41.1817 34.1406Z" fill="white"/>\n' +
				'  <path d="M34.4319 0.12146C34.4259 0.912034 34.516 1.7175 34.3965 2.49045C33.9532 5.35548 32.6082 7.74509 30.3439 9.56572C28.6655 10.9152 26.7659 11.7205 24.5204 11.5809C24.3928 10.7156 24.4212 9.85698 24.6077 9.01641C25.2746 6.01026 26.8737 3.61709 29.4414 1.90903C30.7667 1.02747 32.2209 0.425695 33.8111 0.193454C33.8872 0.182252 34.2992 0.12146 34.4319 0.12146Z" fill="white"/>\n' +
				'</svg>',

		xamarin: '<svg xmlns="http://www.w3.org/2000/svg" width="54" height="48" viewBox="0 0 54 48" fill="none">\n' +
				'  <path fill-rule="evenodd" clip-rule="evenodd" d="M15.5241 0C14.071 0.00308967 12.6486 0.830132 11.9171 2.09181L0.540075 21.9082C-0.180025 23.1732 -0.180025 24.8268 0.540075 26.0918L11.9171 45.9082C12.6487 47.1699 14.071 47.9971 15.5241 48H38.2781C39.7312 47.9969 41.1535 47.1699 41.8851 45.9082L53.2621 26.0918C53.9822 24.8268 53.9822 23.1732 53.2621 21.9082L41.8851 2.09181C41.1535 0.830132 39.7312 0.00285795 38.2781 0H15.5241ZM15.7308 11.5929C15.7621 11.5898 15.7949 11.5898 15.8262 11.5929H19.751C19.9247 11.5965 20.0932 11.6972 20.18 11.8484L26.8378 23.7446C26.8713 23.8034 26.8929 23.869 26.9011 23.9362C26.9093 23.8689 26.9309 23.8034 26.9644 23.7446L33.6063 11.8484C33.6958 11.6926 33.8722 11.5914 34.0512 11.5929H37.9759C38.3234 11.596 38.5837 12.0349 38.4208 12.3434L31.922 24.0001L38.4208 35.6408C38.5991 35.9511 38.3324 36.4104 37.9759 36.4073H34.0512C33.8691 36.4057 33.6916 36.2975 33.6063 36.1358L26.9644 24.2396C26.9309 24.1808 26.9093 24.1153 26.9011 24.048C26.8929 24.1153 26.8713 24.1808 26.8378 24.2396L20.18 36.1358C20.0972 36.2929 19.9277 36.4001 19.751 36.4073H15.8262C15.4697 36.4104 15.2031 35.9511 15.3814 35.6408L21.8802 24.0001L15.3814 12.3434C15.2256 12.0619 15.4156 11.653 15.7308 11.5929Z" fill="white"/>\n' +
				'</svg>',

		flutter: '<svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60" fill="none">\n' +
				'  <path d="M17.7273 37.6705L10 30L35 5H50.3977L17.7273 37.6705ZM35 55L21.5341 41.5341L35 28.0682H50.3977L36.9318 41.5341L50.3977 55H35Z" fill="white"/>\n' +
				'</svg>',

		vueJs: '<svg xmlns="http://www.w3.org/2000/svg" width="57" height="48" viewBox="0 0 57 48" fill="none">\n' +
				'  <path d="M56.1509 0L28.076 48L0 0H11.3075L28.076 28.67L44.8451 0H56.1509Z" fill="white"/>\n' +
				'  <path d="M41 0L27.9998 24L15 0H23.3453L27.9998 8.59365L32.6547 0H41Z" fill="white"/>\n' +
				'</svg>',

		azure: '<svg xmlns="http://www.w3.org/2000/svg" width="60" height="46" viewBox="0 0 60 46" fill="none">\n' +
				'  <path d="M16.2118 9.32329C16.0418 9.41374 15.9015 9.55138 15.8078 9.71964L0.827734 36.6218C0.456577 37.2883 0.9385 38.1083 1.70142 38.1083H17.0269C17.4292 38.1083 17.7924 37.8671 17.9485 37.4963L32.6464 2.59159C33.0116 1.72436 32.0859 0.878722 31.2551 1.32065L16.2118 9.32329Z" fill="white"/>\n' +
				'  <path d="M37.241 4.97173C36.8348 4.24224 35.7663 4.30238 35.4446 5.07286L27.3487 24.4583C27.2126 24.7842 27.2585 25.1576 27.4694 25.4409L38.7225 40.5534C39.2137 41.2131 38.7429 42.1507 37.9204 42.1507H15.7847C15.2324 42.1507 14.7847 42.5984 14.7847 43.1507V44.844C14.7847 45.3963 15.2324 45.844 15.7847 45.844H58.2986C59.0615 45.844 59.5434 45.024 59.1723 44.3575L37.241 4.97173Z" fill="white"/>\n' +
				'</svg>',

		dotNetGreen: '<svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60" fill="none">\n' +
				'  <path fill-rule="evenodd" clip-rule="evenodd" d="M37.2107 35.7473C37.4014 35.2566 37.6177 34.7736 37.779 34.2733C38.6902 31.4469 39.5927 28.6181 40.4891 25.7869C40.8336 24.6993 41.2112 23.6283 41.889 22.6936C42.9611 21.2165 44.4546 20.4427 46.222 20.1723C46.9154 20.0663 47.6231 20.0173 48.3248 20.0106C50.4224 19.9907 52.5209 20.0046 54.6189 20.0066C54.7304 20.0066 54.8419 20.0181 55 20.0273C54.9486 20.1484 54.9207 20.2364 54.8761 20.3156C54.0976 21.7072 53.3341 23.107 52.5324 24.4846C51.1365 26.8829 49.7382 29.2804 48.2985 31.6524C47.375 33.1734 46.3734 34.6473 45.2371 36.0229C44.3988 37.0376 43.5163 38.0078 42.3912 38.7191C41.6246 39.2038 40.8057 39.4873 39.8762 39.4825C36.5794 39.4646 33.2827 39.4778 29.9859 39.473C29.0058 39.4714 28.1667 39.0982 27.4546 38.4363C26.4339 37.4881 25.8245 36.281 25.3259 35.0081C24.4163 32.6843 23.9069 30.2486 23.3223 27.8339C23.0483 26.7037 22.7368 25.5822 22.4401 24.4575C22.421 24.3846 22.3836 24.3165 22.3406 24.2118L22.1108 24.4276C20.8252 25.7049 19.693 27.1123 18.6798 28.6085C18.4038 29.0164 18.311 29.554 18.1569 30.0387C17.5898 31.8189 17.0728 33.6162 16.4587 35.3797C15.8705 37.0691 14.8159 38.4128 13.1675 39.2069C12.2037 39.6709 11.1746 39.9218 10.1053 39.9457C8.52027 39.9808 6.93441 39.9847 5.34895 39.9991C4.96981 40.0027 4.95229 39.9716 5.05106 39.6048C5.73088 37.0807 6.59231 34.621 7.67158 32.2402C8.78431 29.7842 10.095 27.442 11.7461 25.303C12.8704 23.8466 14.1122 22.5029 15.6662 21.4869C16.4543 20.972 17.2923 20.565 18.2457 20.4578C18.4026 20.4399 18.5615 20.4287 18.7192 20.4287C22.0956 20.4272 25.4721 20.4614 28.8481 20.4132C30.659 20.3873 31.97 21.152 32.9517 22.6096C33.5622 23.516 33.9991 24.5057 34.3667 25.528C35.1079 27.5902 35.5687 29.7284 36.0892 31.8515C36.3871 33.0666 36.7188 34.2733 37.0366 35.4836C37.0605 35.5744 37.0992 35.6613 37.1314 35.7497L37.2107 35.7473Z" fill="#69B518"/>\n' +
				'</svg>',

		javaGreen: '<svg xmlns="http://www.w3.org/2000/svg" width="45" height="60" viewBox="0 0 45 60" fill="none">\n' +
				'  <path d="M22.456 11.1172C19.6836 13.0671 16.5414 15.2773 14.8579 18.9338C11.9336 25.3127 20.8417 32.2711 21.2224 32.5642C21.3109 32.6325 21.4168 32.6663 21.5224 32.6663C21.6345 32.6663 21.7468 32.628 21.8381 32.5516C22.015 32.4037 22.0651 32.1525 21.9589 31.9479C21.9267 31.8857 18.7291 25.6748 18.848 21.2189C18.8901 19.6671 21.0619 17.9067 23.3611 16.0427C25.4671 14.3356 27.8541 12.4006 29.2508 10.1372C32.3169 5.15479 28.9091 0.255638 28.8743 0.206851C28.7389 0.016818 28.4891 -0.0522313 28.2755 0.0416049C28.062 0.135638 27.9438 0.366589 27.9926 0.594589C27.9995 0.626852 28.6622 3.86666 26.8181 7.20444C26.0582 8.58365 24.389 9.75788 22.456 11.1172Z" fill="#69B518"/>\n' +
				'  <path d="M34.1348 13.3139C34.3304 13.1666 34.3878 12.8979 34.2694 12.6834C34.1509 12.469 33.893 12.3744 33.6641 12.4613C33.1931 12.6403 22.1309 16.9043 22.1309 22.0566C22.1309 25.608 23.648 27.4844 24.7557 28.8545C25.1903 29.3918 25.5654 29.8559 25.689 30.2521C26.037 31.3938 25.2125 33.4574 24.8659 34.1562C24.7659 34.3572 24.8149 34.6006 24.9849 34.7473C25.0768 34.8266 25.1915 34.8667 25.3063 34.8667C25.4041 34.8667 25.5023 34.8376 25.5871 34.7786C25.7773 34.6462 30.2356 31.4942 29.4359 27.6964C29.1381 26.2531 28.4287 25.1983 27.8027 24.2676C26.8331 22.8258 26.1328 21.7842 27.1953 19.8604C28.4405 17.617 34.078 13.3566 34.1348 13.3139Z" fill="#69B518"/>\n' +
				'  <path d="M5.33334 34.9494C5.1437 35.517 5.23321 36.0806 5.59203 36.5789C6.79125 38.2435 11.0389 39.1604 17.5531 39.1604C17.5535 39.1604 17.5537 39.1604 17.5539 39.1604C18.4366 39.1604 19.3684 39.1431 20.3229 39.1089C30.738 38.7359 34.601 35.4914 34.7603 35.3535C34.934 35.2032 34.9805 34.9528 34.8725 34.7501C34.7645 34.5477 34.5316 34.446 34.3091 34.5072C30.6412 35.5087 23.7917 35.8642 19.0409 35.8642C13.7249 35.8642 11.0176 35.4814 10.3549 35.1987C10.695 34.7317 12.7926 33.8978 15.3919 33.3865C15.6404 33.3377 15.8111 33.1083 15.7865 32.8563C15.7619 32.6043 15.5503 32.4121 15.2971 32.4121C13.7733 32.4123 6.13203 32.5347 5.33334 34.9494Z" fill="#69B518"/>\n' +
				'  <path d="M40.1907 31.6077C38.0425 31.6077 36.0055 32.6841 35.9197 32.73C35.721 32.8364 35.6203 33.0642 35.6748 33.2829C35.7295 33.5015 35.926 33.6551 36.1514 33.6553C36.1971 33.6555 40.7337 33.6925 41.1446 36.2845C41.5086 38.5207 36.8522 42.1439 35.0272 43.3514C34.8301 43.4818 34.7535 43.734 34.845 43.9518C34.9227 44.1373 35.1035 44.2533 35.2983 44.2533C35.3323 44.2533 35.3667 44.2498 35.4009 44.2423C35.8341 44.1499 45.9954 41.9108 44.9211 35.9944C44.2656 32.3686 41.9868 31.6077 40.1907 31.6077Z" fill="#69B518"/>\n' +
				'  <path d="M33.2165 42.6272C33.2519 42.4375 33.1731 42.2447 33.0153 42.1342L30.5789 40.4276C30.4601 40.3444 30.31 40.3182 30.1705 40.3552C30.1451 40.3619 27.5972 41.0316 23.898 41.4423C22.4299 41.607 20.7855 41.6939 19.1423 41.6939C15.4441 41.6939 13.026 41.2594 12.6739 40.9407C12.6273 40.8512 12.642 40.8104 12.6503 40.7878C12.7142 40.6098 13.0571 40.3983 13.2782 40.3147C13.5223 40.2244 13.6539 39.9592 13.578 39.7102C13.5023 39.4611 13.2456 39.3144 12.9922 39.3754C10.5481 39.9651 9.35302 40.79 9.43997 41.8267C9.5944 43.6647 13.852 44.6081 17.4515 44.8578C17.969 44.8932 18.5289 44.9113 19.1149 44.9113C19.1153 44.9113 19.1155 44.9113 19.1159 44.9113C25.1012 44.9113 32.7741 43.0334 32.8508 43.0143C33.0381 42.9681 33.1809 42.8168 33.2165 42.6272Z" fill="#69B518"/>\n' +
				'  <path d="M14.8553 46.5725C15.0398 46.4523 15.1216 46.2243 15.0561 46.0142C14.9908 45.8041 14.7943 45.6662 14.5735 45.669C14.246 45.6776 11.3686 45.8075 11.1671 47.6395C11.1061 48.187 11.2629 48.6871 11.6333 49.126C12.6665 50.3504 15.4553 51.078 20.1577 51.3509C20.714 51.3841 21.279 51.4009 21.8375 51.4009C27.8159 51.4009 31.8422 49.5291 32.0108 49.4494C32.1742 49.3721 32.282 49.2116 32.2917 49.031C32.3013 48.8506 32.2112 48.6792 32.057 48.585L28.9779 46.7053C28.8711 46.6402 28.7432 46.6186 28.6212 46.6435C28.6018 46.6477 26.6467 47.0531 23.6963 47.4538C23.138 47.5296 22.4389 47.5681 21.6185 47.5681C18.6713 47.5681 15.3931 47.0866 14.7644 46.7714C14.7553 46.7122 14.7669 46.6374 14.8553 46.5725Z" fill="#69B518"/>\n' +
				'  <path d="M19.047 57.0521C32.7407 57.0405 40.0905 54.6053 41.5063 53.0729C42.0075 52.5305 42.0616 52.0173 42.0189 51.682C41.9137 50.857 41.1624 50.352 41.077 50.2971C40.8715 50.1649 40.5956 50.1995 40.4353 50.3845C40.2754 50.5696 40.2716 50.8397 40.4322 51.0244C40.5185 51.1356 40.5685 51.3214 40.3159 51.5742C39.7499 52.1028 34.0411 53.7075 24.5359 54.1895C23.2338 54.2573 21.8681 54.2916 20.4769 54.2917C11.9664 54.2917 5.73795 53.1262 4.92037 52.4467C5.23572 51.994 7.43861 51.2705 9.78156 50.8615C10.0456 50.8155 10.224 50.5666 10.1831 50.3018C10.1422 50.0371 9.89802 49.8547 9.63127 49.8895C9.56517 49.8984 9.3411 49.9121 9.08123 49.9279C5.21546 50.1639 0.748103 50.6902 0.514987 52.6818C0.444168 53.2889 0.624561 53.8397 1.05125 54.3193C2.09525 55.4923 5.09624 57.052 19.0464 57.052C19.0468 57.0521 19.0468 57.0521 19.047 57.0521Z" fill="#69B518"/>\n' +
				'  <path d="M44.1959 53.5052C44.0024 53.4157 43.7738 53.461 43.6292 53.6168C43.6089 53.6384 41.5502 55.7913 35.36 57.0555C32.9901 57.5304 28.5416 57.7712 22.1381 57.7712C15.7226 57.7712 9.61817 57.5188 9.55719 57.5162C9.30223 57.5038 9.08407 57.6891 9.04945 57.9401C9.01463 58.1911 9.17633 58.4274 9.42263 58.486C9.48617 58.5012 15.8838 60 24.6312 60C28.8267 60 32.9092 59.6591 36.7658 58.9867C43.9565 57.7261 44.4599 54.1605 44.4778 54.0094C44.5028 53.7982 44.3891 53.5947 44.1959 53.5052Z" fill="#69B518"/>\n' +
				'</svg>',

		angularGreen: '<svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">\n' +
				'  <path fill-rule="evenodd" clip-rule="evenodd" d="M24 0L0 7.968L3.66058 37.512L24 48L44.3394 37.512L48 7.968L24 0ZM38.9804 36.6225H33.3864L30.3703 29.6145H24.003H17.6356L14.6195 36.6225H9.02555L24.003 5.3025L38.9804 36.6225ZM24.6285 25.2H20.2074L24.6285 15.6L29.0495 25.2H24.6285Z" fill="#69B518"/>\n' +
				'</svg>',

		reactGreen: '<svg xmlns="http://www.w3.org/2000/svg" width="50" height="54" viewBox="0 0 50 54" fill="none">\n' +
				'  <path fill-rule="evenodd" clip-rule="evenodd" d="M0 27.3684C0 33.2595 11.2326 37.5212 25 37.5212C38.7674 37.5212 50 33.2595 50 27.3684C50 21.4772 38.7674 17.2155 25 17.2155C11.2326 17.2155 0 21.4772 0 27.3684ZM47.825 27.3688C47.825 31.4992 37.6452 35.3615 24.9989 35.3615C12.3527 35.3615 2.17285 31.4992 2.17285 27.3688C2.17285 23.2385 12.3527 19.3762 24.9989 19.3762C37.6452 19.3762 47.825 23.2385 47.825 27.3688Z" fill="#69B518"/>\n' +
				'  <path fill-rule="evenodd" clip-rule="evenodd" d="M12.4959 5.09552C7.37485 8.05215 9.28652 19.9188 16.1702 31.8417C23.0539 43.7646 32.3749 51.3534 37.4959 48.3968C42.617 45.4401 40.7053 33.5735 33.8216 21.6506C26.9379 9.72773 17.617 2.13888 12.4959 5.09552ZM36.4104 46.5136C32.82 48.5866 24.3726 41.709 18.0495 30.757C11.7264 19.805 9.99389 9.05062 13.5843 6.97767C17.1748 4.90472 25.6222 11.7823 31.9453 22.7343C38.2684 33.6862 40.0009 44.4407 36.4104 46.5136Z" fill="#69B518"/>\n' +
				'  <path fill-rule="evenodd" clip-rule="evenodd" d="M37.4959 5.09571C32.3749 2.13907 23.0539 9.72792 16.1702 21.6508C9.28652 33.5737 7.37485 45.4403 12.4959 48.397C17.617 51.3536 26.9379 43.7648 33.8216 31.8419C40.7053 19.919 42.617 8.05235 37.4959 5.09571ZM13.5854 46.514C9.99495 44.441 11.7274 33.6866 18.0506 22.7346C24.3737 11.7827 32.821 4.90509 36.4115 6.97804C40.0019 9.05099 38.2694 19.8054 31.9463 30.7574C25.6232 41.7093 17.1759 48.5869 13.5854 46.514Z" fill="#69B518"/>\n' +
				'  <ellipse cx="25.0032" cy="27.3673" rx="5" ry="5.07642" fill="#69B518"/>\n' +
				'</svg>',

		androidGreen: '<svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none">\n' +
				'  <path d="M45.5552 41.0159C43.132 41.0159 41.112 39.0447 41.112 36.6215V24.9032C41.112 22.48 43.132 20.5088 45.5552 20.5088C47.9784 20.5088 49.9984 22.48 49.9984 24.9032V36.6215C49.9984 39.0448 47.9785 41.0159 45.5552 41.0159Z" fill="#69B518"/>\n' +
				'  <path d="M4.44338 41.0159C2.02023 41.0159 0.000183105 39.0447 0.000183105 36.6215V24.9032C0.000183105 22.48 2.02023 20.5088 4.44338 20.5088C6.86654 20.5088 8.88659 22.48 8.88659 24.9032V36.6215C8.88659 39.0448 6.86664 41.0159 4.44338 41.0159Z" fill="#69B518"/>\n' +
				'  <path fill-rule="evenodd" clip-rule="evenodd" d="M38.1824 16.1144C38.1824 16.9348 37.538 17.5792 36.7176 17.5792H13.281C12.4606 17.5792 11.8162 16.9348 11.8162 16.1144C11.8162 11.0169 14.7457 6.65193 18.9936 4.45474L17.8217 2.11098C17.4702 1.40788 17.7632 0.529099 18.4956 0.148253C19.1987 -0.203297 20.0775 0.0897594 20.4583 0.822155L21.7474 3.39999C22.802 3.10713 23.8566 2.93136 24.9992 2.93136C26.1418 2.93136 27.1964 3.10713 28.251 3.39999L29.54 0.822155C29.9209 0.0897594 30.7998 -0.203297 31.5028 0.148253C32.2352 0.529099 32.5282 1.40797 32.1767 2.11098L31.0047 4.45474C35.2528 6.65193 38.1824 11.017 38.1824 16.1144ZM22.558 10.2552C22.558 11.0642 21.9022 11.72 21.0932 11.72C20.2842 11.72 19.6284 11.0642 19.6284 10.2552C19.6284 9.44623 20.2842 8.79042 21.0932 8.79042C21.9022 8.79042 22.558 9.44623 22.558 10.2552ZM28.9054 11.72C29.7144 11.72 30.3702 11.0642 30.3702 10.2552C30.3702 9.44623 29.7144 8.79042 28.9054 8.79042C28.0964 8.79042 27.4406 9.44623 27.4406 10.2552C27.4406 11.0642 28.0964 11.72 28.9054 11.72Z" fill="#69B518"/>\n' +
				'  <path d="M36.7176 20.5088H13.281C12.4606 20.5088 11.8162 21.1532 11.8162 21.9736V39.5511C11.8162 40.3715 12.4606 41.0159 13.281 41.0159H14.7457V45.6056C14.7457 48.0372 16.7085 49.9999 19.1401 49.9999C21.5718 49.9999 23.5345 48.0372 23.5345 45.6056V41.0159H26.4641V45.6056C26.4641 48.0372 28.4268 49.9999 30.8585 49.9999C33.2901 49.9999 35.2528 48.0372 35.2528 45.6056V41.0159H36.7176C37.538 41.0159 38.1824 40.3715 38.1824 39.5511V21.9736C38.1824 21.1533 37.538 20.5088 36.7176 20.5088Z" fill="#69B518"/>\n' +
				'</svg>',

		iosGreen: '<svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none">\n' +
				'  <path d="M16.624 49.7996C15.2212 49.7448 13.1258 48.2155 12.2181 47.1864C9.89542 44.5528 8.00335 41.6346 6.66523 38.3828C5.14201 34.6814 4.2334 30.8428 4.27944 26.813C4.3125 23.9144 4.87835 21.1342 6.32944 18.604C8.12357 15.4757 10.7723 13.4183 14.3124 12.5866C15.721 12.2558 17.1569 12.123 18.5647 12.5395C19.9722 12.9559 21.3591 13.4474 22.7401 13.947C23.9367 14.3799 25.111 14.6932 26.3673 14.1488C27.1927 13.791 28.0512 13.5093 28.8965 13.1983C30.2811 12.6887 31.6805 12.2163 33.1711 12.1719C36.054 12.0858 38.7135 12.7929 41.0595 14.5187C42.027 15.2305 42.8402 16.0952 43.515 17.1084C43.4222 17.1783 43.3512 17.2362 43.2759 17.288C42.2655 17.9827 41.3707 18.8026 40.5967 19.7543C38.542 22.2801 37.8289 25.1633 38.3139 28.3605C38.6523 30.5914 39.6136 32.5172 41.1819 34.1405C42.1404 35.1325 43.7497 36.1702 44.9882 36.7716C44.7723 37.2834 44.3491 38.1092 44.1435 38.6257C43.2662 40.8304 42.0854 42.8655 40.6961 44.7849C39.7081 46.1498 38.7139 47.5032 37.364 48.5513C36.1289 49.5103 34.7618 49.9896 33.1865 49.8802C31.9865 49.7969 30.8758 49.422 29.8006 48.9243C27.4513 47.8366 25.04 47.6196 22.562 48.3952C21.9439 48.5887 21.3609 48.8922 20.7446 49.0925C19.7662 49.4104 17.6521 49.8398 16.624 49.7996Z" fill="#69B518"/>\n' +
				'  <path d="M41.1817 34.1406C39.6134 32.5173 38.6521 30.5916 38.3138 28.3607C37.8288 25.1634 38.5419 22.2802 40.5966 19.7544C41.3706 18.8028 42.2654 17.9828 43.2758 17.2882C43.3511 17.2364 43.422 17.1785 43.5148 17.1085C42.84 16.0954 42.0268 15.2307 41.0593 14.5189C38.7133 12.7932 36.0537 12.0861 33.1709 12.172C31.6805 12.2166 30.2809 12.6889 28.8963 13.1984C28.051 13.5095 27.1924 13.7913 26.3671 14.149C25.754 14.4147 25.1605 14.4752 24.5742 14.4181V47.9824C26.3501 47.8055 28.0918 48.1335 29.8004 48.9245C30.8756 49.4223 31.9865 49.797 33.1863 49.8804C34.7618 49.9898 36.1288 49.5103 37.3638 48.5514C38.7137 47.5033 39.7079 46.1499 40.6959 44.785C42.0853 42.8656 43.266 40.8305 44.1433 38.6259C44.3488 38.1095 44.7721 37.2837 44.988 36.7718C43.7495 36.1704 42.1402 35.1327 41.1817 34.1406Z" fill="#69B518"/>\n' +
				'  <path d="M34.4319 0.12146C34.4259 0.912034 34.516 1.7175 34.3965 2.49045C33.9532 5.35548 32.6082 7.74509 30.3439 9.56572C28.6655 10.9152 26.7659 11.7205 24.5204 11.5809C24.3928 10.7156 24.4212 9.85698 24.6077 9.01641C25.2746 6.01026 26.8737 3.61709 29.4414 1.90903C30.7667 1.02747 32.2209 0.425695 33.8111 0.193454C33.8872 0.182252 34.2992 0.12146 34.4319 0.12146Z" fill="#69B518"/>\n' +
				'</svg>',

		xamarinGreen: '<svg xmlns="http://www.w3.org/2000/svg" width="54" height="48" viewBox="0 0 54 48" fill="none">\n' +
				'  <path fill-rule="evenodd" clip-rule="evenodd" d="M15.5241 0C14.071 0.00308967 12.6486 0.830132 11.9171 2.09181L0.540075 21.9082C-0.180025 23.1732 -0.180025 24.8268 0.540075 26.0918L11.9171 45.9082C12.6487 47.1699 14.071 47.9971 15.5241 48H38.2781C39.7312 47.9969 41.1535 47.1699 41.8851 45.9082L53.2621 26.0918C53.9822 24.8268 53.9822 23.1732 53.2621 21.9082L41.8851 2.09181C41.1535 0.830132 39.7312 0.00285795 38.2781 0H15.5241ZM15.7308 11.5929C15.7621 11.5898 15.7949 11.5898 15.8262 11.5929H19.751C19.9247 11.5965 20.0932 11.6972 20.18 11.8484L26.8378 23.7446C26.8713 23.8034 26.8929 23.869 26.9011 23.9362C26.9093 23.8689 26.9309 23.8034 26.9644 23.7446L33.6063 11.8484C33.6958 11.6926 33.8722 11.5914 34.0512 11.5929H37.9759C38.3234 11.596 38.5837 12.0349 38.4208 12.3434L31.922 24.0001L38.4208 35.6408C38.5991 35.9511 38.3324 36.4104 37.9759 36.4073H34.0512C33.8691 36.4057 33.6916 36.2975 33.6063 36.1358L26.9644 24.2396C26.9309 24.1808 26.9093 24.1153 26.9011 24.048C26.8929 24.1153 26.8713 24.1808 26.8378 24.2396L20.18 36.1358C20.0972 36.2929 19.9277 36.4001 19.751 36.4073H15.8262C15.4697 36.4104 15.2031 35.9511 15.3814 35.6408L21.8802 24.0001L15.3814 12.3434C15.2256 12.0619 15.4156 11.653 15.7308 11.5929Z" fill="#69B518"/>\n' +
				'</svg>',

		flutterGreen: '<svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60" fill="none">\n' +
				'  <path d="M17.7273 37.6705L10 30L35 5H50.3977L17.7273 37.6705ZM35 55L21.5341 41.5341L35 28.0682H50.3977L36.9318 41.5341L50.3977 55H35Z" fill="#69B518"/>\n' +
				'</svg>',

		vueJsGreen: '<svg xmlns="http://www.w3.org/2000/svg" width="57" height="48" viewBox="0 0 57 48" fill="none">\n' +
				'  <path d="M56.1509 0L28.076 48L0 0H11.3075L28.076 28.67L44.8451 0H56.1509Z" fill="#69B518"/>\n' +
				'  <path d="M41 0L27.9998 24L15 0H23.3453L27.9998 8.59365L32.6547 0H41Z" fill="#69B518"/>\n' +
				'</svg>',

		azureGreen: '<svg xmlns="http://www.w3.org/2000/svg" width="60" height="46" viewBox="0 0 60 46" fill="none">\n' +
				'  <path d="M16.2118 9.32329C16.0418 9.41374 15.9015 9.55138 15.8078 9.71964L0.827734 36.6218C0.456577 37.2883 0.9385 38.1083 1.70142 38.1083H17.0269C17.4292 38.1083 17.7924 37.8671 17.9485 37.4963L32.6464 2.59159C33.0116 1.72436 32.0859 0.878722 31.2551 1.32065L16.2118 9.32329Z" fill="#69B518"/>\n' +
				'  <path d="M37.241 4.97173C36.8348 4.24224 35.7663 4.30238 35.4446 5.07286L27.3487 24.4583C27.2126 24.7842 27.2585 25.1576 27.4694 25.4409L38.7225 40.5534C39.2137 41.2131 38.7429 42.1507 37.9204 42.1507H15.7847C15.2324 42.1507 14.7847 42.5984 14.7847 43.1507V44.844C14.7847 45.3963 15.2324 45.844 15.7847 45.844H58.2986C59.0615 45.844 59.5434 45.024 59.1723 44.3575L37.241 4.97173Z" fill="#69B518"/>\n' +
				'</svg>'
}
